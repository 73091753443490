import React, { useState } from 'react';
import "./Appointment.css"
import axios from 'axios'; // Assuming you're using axios for API requests

const BookingForm = () => {
  const initialFormData = {
    fullName: '',
    mobileNumber: '',
    email: '',
    description: '',
    address: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading ] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when form is submitted
    try {
      // Replace 'your-api-endpoint' with the actual endpoint for your API
      await axios.post('https://ahamed-backend.vercel.app/online-patient', formData);

      // Handle successful booking, e.g., show a success message
      window.alert('Appointment booked successfully!');

      // Clear form fields after successful submission
      setFormData(initialFormData);
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error('Error booking appointment:', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="background h-screen">
      <div className="form-container">
        
        <form onSubmit={handleSubmit}>
          <label>
            Full Name:
            <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
          </label>
          <label>
            Phone Number:
            <input type="tel" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} required />
          </label>
          <label>
            Email:
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          </label>
          <label>
            Your Message:
            <textarea name="description" value={formData.description} onChange={handleChange} required />
          </label>
          <label>
            Address:
            <input type="text" name="address" value={formData.address} onChange={handleChange} required />
          </label>
          <button type="submit" className='button-002  text-white p-2 border-none cursor-pointer mt-2 rounded-md' disabled={loading}>
           {loading ? 'Please wait..' :"Book Appointment"}</button>
        </form>
      </div>
    </div>
  );
};

export default BookingForm;
