import React from 'react'
import Home from './Home/Home'
function Tree() {
  return (
    <div>
       <Home />
    </div>
  )
}

export default Tree
