import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Home/Home';
import Tree from './Tree';
import About from './Components/About/About';
import Contact from './Components/Appointment/contact';
import Appointment from './Components/Appointment/Appointment';
// import Navbar from './Components/nav/navbar';

function App() {
  return (
    <div className="App">
     
      <Routes>
        <Route path='/' element={<Tree />} />
        <Route path='/appointment' element={<Appointment />} />
        <Route path='/about' element= {<About/>}/>
        <Route path='/contact' element= {<Contact/>}/>
      </Routes>
    </div>
  );
}

export default App;
